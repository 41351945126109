import { h, Component, createRef } from "preact";
import { StoreContextWrapper, Actions } from "../StoreContextWrapper";
import { connect } from "unistore/preact";
import { IntlProvider } from "preact-i18n";
import { translations } from "../../locales";
import MvAddressSelectorSDKOptions from "../../models/MvAddressSelectorSDKOptions";
import ModalWrapper from "../Modal";
import { EventEmitter2 } from "eventemitter2";
import Footer from "../Footer";
import merge from "deepmerge";
import MainContent from "../MainContent";
import Map from "../Map";
import { uuidv4, defaultInputParams } from "../../services/helpers";

import styles from "./style.scss";
import MvArea from "../../models/MvArea";
import AsidePanel from "../AsidePanel";
import Summary from "../Summary";
import MvAdressResult from "../../models/MvAddressResult";

export interface ModalAppProps {
    options: MvAddressSelectorSDKOptions;
    error?: string;
    getLocations(): Promise<void>;
    stopLoading(): void;
    setError(error: any): void;
    startLoading(): void;
    setApiBasePath(basePath: string): void;
}

export interface ModalAppState {
    definition: {};
    areas: MvArea[];
    selectedGroups: any[];
    panelOpen: boolean;
    showSummary: boolean;
    isLoading: boolean;
}

class ModalApp extends Component<ModalAppProps, ModalAppState> {
    constructor(props: ModalAppProps) {
        super(props);

        const { selectedAddress, messages, language } = props.options;

        const baseMessages: any =
            language && translations[language]
                ? translations[language]
                : translations["en"];

        // If translation messages exists, overwrite base object
        this.state = {
            definition:
                messages && Object.keys(messages).length > 0
                    ? merge(baseMessages, messages)
                    : baseMessages,
            areas: selectedAddress
                ? [
                      {
                          id: uuidv4(),
                          inputParams: {
                              ...defaultInputParams,
                              streetName: selectedAddress.streetname,
                              countryCode: selectedAddress.countryCode,
                              postalCode: selectedAddress.postcode,
                              houseNumber: selectedAddress.huisnr,
                              houseNumberExtensionLetter:
                                  selectedAddress.huisnr_bag_letter,
                              houseNumberExtensionNumber:
                                  selectedAddress.huisnr_bag_toevoeging,
                          },
                          result: null,
                          isSelected: true,
                          disabled: true,
                          selectedAddresses: [],
                      },
                  ]
                : [],
            panelOpen: false,
            selectedGroups: [],
            showSummary: false,
            isLoading: true,
        };

        this.bindEvents(props.options.onComplete, props.options.onError);
    }

    map = createRef<any>();

    /**
     * Create global event emitter
     * @type {EventEmitter2}
     */
    events = new EventEmitter2();

    componentDidMount() {
        this.prepareInitialStore(this.props.options);
    }

    componentDidUpdate(prevProps: ModalAppProps) {
        // this.tokenValidation(prevProps.options, this.props.options);
        this.prepareInitialStore(prevProps.options);
        this.rebindEvents(prevProps.options, this.props.options);
    }

    componentWillUnmount() {
        this.events.removeAllListeners(["complete", "error", "finish"]);
    }

    bindEvents = (onComplete: any, onError: any) => {
        if (onComplete) {
            this.events.on("complete", onComplete);
        }
        if (onError) {
            this.events.on("error", onError);
        }
    };

    rebindEvents = (
        oldOptions: MvAddressSelectorSDKOptions,
        newOptions: MvAddressSelectorSDKOptions
    ) => {
        if (oldOptions.onComplete) {
            this.events.off("complete", oldOptions.onComplete);
        }
        if (oldOptions.onError) {
            this.events.off("error", oldOptions.onError);
        }
        this.bindEvents(newOptions.onComplete, newOptions.onError);
    };

    /**
     * Prepare initial store
     * See https://github.com/developit/unistore
     * @param  {Object} options:      MvAddressSelectorSDKOptions new config
     * @return {Function}
     */
    prepareInitialStore = (options: MvAddressSelectorSDKOptions) => {
        const { apiBasePath } = options;

        if (apiBasePath) {
            this.props.setApiBasePath(apiBasePath);
        }

        this.context.store.subscribe((data) => {
            if (this.state.isLoading !== data.isLoading) {
                this.setState({ isLoading: data.isLoading });
            }
        });
    };

    onAreasUpdate = (areas: MvArea[]) => {
        this.setState(
            {
                areas,
            },
            () => {
                this.map.current?.update(areas);
            }
        );
    };

    onSelect = (area: MvArea) => {
        const newAreas = [
            ...this.state.areas.map((a) => {
                if (a.id === area.id) {
                    return { ...a, isSelected: true };
                } else {
                    return { ...a, isSelected: false };
                }
            }),
        ];
        this.setState({ areas: newAreas }, () => {
            this.map.current?.update(newAreas);
        });
    };

    filterAreasBySelectedGroups = (groups: any[]) => {
        return [
            ...this.state.areas.map((a) => {
                const matchingSelection = groups.find((g) => g.id === a.id);
                return {
                    ...a,
                    selectedAddresses: [
                        ...a.selectedAddresses.filter(
                            (address) =>
                                matchingSelection.selectedStreetNames.indexOf(
                                    address.straatnaam
                                ) > -1
                        ),
                        {
                            ...a.result,
                            id: a.id,
                        },
                    ],
                };
            }),
        ];
    };

    addressAlreadyExists = (address: MvAdressResult) => {
        const findAlreadyExisting = this.state.areas.filter(
            (a) => a.result && a.result.id === address.id
        );
        if (findAlreadyExisting.length > 0) {
            return true;
        }
        return false;
    };

    addressItemAlreadyExists = (area: MvArea, address: MvAdressResult) => {
        let exists = false;
        const areaList = [...this.state.areas];

        // return false if this area exists already in the state
        if (
            areaList.filter(
                (list) =>
                    list.id === area.id && list.selectedAddresses.length > 0
            ).length > 0
        ) {
            return false;
        }

        for (const area of areaList) {
            const list = area.selectedAddresses.filter(
                (item) => item.id === address.id
            );
            if (list.length > 0) {
                exists = true;
            }
        }
        return exists;
    };

    handleAddAddress = (address: MvAdressResult) => {
        if (this.addressAlreadyExists(address)) {
            return;
        }
        this.setState({
            areas: [
                ...this.state.areas.map((a) => {
                    return { ...a, isSelected: false };
                }),
                {
                    id: uuidv4(),
                    inputParams: defaultInputParams,
                    result: address,
                    disabled: false,
                    isSelected: true,
                    selectedAddresses: [],
                },
            ],
        });
    };

    updateAddress = (area: MvArea, newResult: MvAdressResult) => {
        if (this.addressAlreadyExists(newResult)) {
            return;
        }
        const newAreas = [
            ...this.state.areas.map((a) => {
                if (a.id === area.id) {
                    return {
                        ...a,
                        inputParams: {
                            ...a.inputParams,
                            houseNumber: newResult.huisnr,
                            postalCode: newResult.postcode,
                        },
                        result: newResult,
                        isSelected: true,
                    };
                }
                return { ...a, isSelected: false };
            }),
        ];
        this.onAreasUpdate(newAreas);
    };

    handleAddressesFound = (areas: MvArea[]) => {
        const newAreas = areas;
        for (let i = 0; i < newAreas.length; i++) {
            newAreas[i].selectedAddresses = [
                ...newAreas[i].selectedAddresses.filter((address) => {
                    return !this.addressItemAlreadyExists(newAreas[i], address);
                }),
            ];
        }
        this.setState({
            areas: newAreas,
        });
    };

    render = (
        {
            options: {
                useModal,
                isModalOpen,
                onModalRequestClose,
                onComplete,
                onCancel,
                pricePerAddress,
                apiBasePath,
                containerId,
                addressLimit,
                shouldCloseOnOverlayClick,
                selectedAddress,
                zipCodeAreaMinCount,
            },
            startLoading,
            stopLoading,
        }: ModalAppProps,
        { definition }: any
    ) => {
        return (
            <IntlProvider definition={definition}>
                <ModalWrapper
                    useModal={useModal}
                    apiBasePath={apiBasePath}
                    isOpen={isModalOpen ? isModalOpen : false}
                    onRequestClose={() =>
                        onModalRequestClose ? onModalRequestClose() : null
                    }
                    containerId={containerId}
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                >
                    {this.state.showSummary && (
                        <Summary
                            areas={this.filterAreasBySelectedGroups(
                                this.state.selectedGroups
                            )}
                            pricePerAddress={pricePerAddress}
                            zipCodeAreaMinCount={zipCodeAreaMinCount}
                            onAreaRemove={(area: MvArea) => {
                                const newAreas = this.state.areas.filter(
                                    (a) => a.id !== area.id
                                );
                                this.onAreasUpdate(newAreas);
                            }}
                            onClose={() =>
                                this.setState({
                                    showSummary: false,
                                    panelOpen: false,
                                })
                            }
                        />
                    )}
                    <div
                        className={`${styles.mvAppWrapper} ${
                            this.state.showSummary ? styles.hide : ""
                        }`}
                    >
                        <MainContent
                            selectedAreas={this.state.areas}
                            apiBasePath={apiBasePath}
                            pricePerAddress={pricePerAddress}
                            onUpdate={this.onAreasUpdate}
                            onAddressesFound={this.handleAddressesFound}
                            onShowAddresses={() =>
                                this.setState({ panelOpen: true })
                            }
                            countryCode={selectedAddress?.countryCode || "nl"}
                            isLoading={this.state.isLoading}
                            startLoading={startLoading}
                            stopLoading={stopLoading}
                        />
                        <Map
                            ref={this.map}
                            apiBasePath={apiBasePath}
                            initialAreas={this.state.areas}
                            onSelect={this.onSelect}
                            addAddress={this.handleAddAddress}
                            updateAddress={this.updateAddress}
                            countryCode={selectedAddress?.countryCode || "nl"}
                            isLoading={this.state.isLoading}
                            startLoading={startLoading}
                            stopLoading={stopLoading}
                        />
                    </div>
                    <AsidePanel
                        isOpen={this.state.panelOpen}
                        isEditable={this.state.showSummary}
                        areas={this.state.areas}
                        onClose={() => this.setState({ panelOpen: false })}
                        zipCodeAreaMinCount={zipCodeAreaMinCount}
                        addressLimit={addressLimit}
                        onConfirm={() => {
                            onComplete
                                ? onComplete({
                                      result: "confirm_selection",
                                      currentSelection:
                                          this.filterAreasBySelectedGroups(
                                              this.state.selectedGroups
                                          ),
                                  })
                                : null;
                        }}
                        onGroupsChange={(groups: any[]) =>
                            this.setState({ selectedGroups: groups })
                        }
                    />
                    {!this.state.showSummary && (
                        <Footer
                            showAll={() => this.setState({ panelOpen: true })}
                            addresses={this.state.areas}
                            pricePerAddress={pricePerAddress}
                            onClose={() => {
                                onCancel
                                    ? onCancel({
                                          type: "cancel",
                                          currentSelection:
                                              this.filterAreasBySelectedGroups(
                                                  this.state.selectedGroups
                                              ),
                                      })
                                    : null;
                            }}
                            onNext={() =>
                                this.setState({
                                    showSummary: true,
                                    panelOpen: true,
                                })
                            }
                            isLoading={this.state.isLoading}
                        />
                    )}
                </ModalWrapper>
            </IntlProvider>
        );
    };
}

/**
 * Connect state to props
 * @param  {Object} state: any
 * @return {State}]
 */
const mapStateToProps = (state: any) => ({
    ...state,
});

const ConnectedModalApp = connect(mapStateToProps, Actions)(ModalApp);

const App = ({ options }: { options: MvAddressSelectorSDKOptions }) => {
    return (
        <StoreContextWrapper>
            <ConnectedModalApp options={options} />
        </StoreContextWrapper>
    );
};

export default App;
