import { h, FunctionalComponent } from "preact";

export interface MapMarkerProps {
    fill?: string;
    className?: string;
}

const MapMarker: FunctionalComponent<MapMarkerProps> = ({
    fill = "#CB0000",
    className,
}: MapMarkerProps) => (
    <svg
        fill="none"
        viewBox="0 0 11 15"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M5.25.75A5.251 5.251 0 0 0 0 6c0 1.969.629 2.57 4.129 8.148.52.82 1.695.82 2.215 0C9.844 8.57 10.5 7.968 10.5 6 10.5 3.102 8.121.75 5.25.75Zm0 12.688C1.75 7.859 1.312 7.503 1.312 6c0-2.16 1.75-3.938 3.938-3.938C7.41 2.063 9.188 3.84 9.188 6c0 1.504-.438 1.805-3.938 7.438ZM3.062 6A2.16 2.16 0 0 0 5.25 8.188 2.176 2.176 0 0 0 7.438 6 2.194 2.194 0 0 0 5.25 3.812c-1.23 0-2.188.985-2.188 2.188Z"
            fill={fill}
        />
    </svg>
);

export default MapMarker;
