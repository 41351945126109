import axios from "axios";

export const getLocations = function (
    apiBasePath: string,
    params: any,
    signal?: any
) {
    let req = { params: params };
    if (signal) {
        req.signal = signal;
    }
    return axios.get(`${apiBasePath}`, req);
};
