import { h, FunctionalComponent, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import MvArea from "../../models/MvArea";
import PlusIcon from "../../icons/PlusIcon";
import Area from "../Area";
import Button from "../Button";
import { uuidv4, defaultInputParams } from "../../services/helpers";
import styles from "./style.scss";
import MvAdressResult from "../../models/MvAddressResult";
import { Text } from "preact-i18n";

export interface MainContentProps {
    apiBasePath?: string;
    selectedAreas: MvArea[];
    onUpdate(areas: MvArea[]): void;
    onAddressesFound(areas: MvArea[]): void;
    onShowAddresses(): void;
    pricePerAddress: number;
    countryCode?: "nl" | "de" | "be" | "fr" | "en";
    isLoading: boolean;
    startLoading?(): void;
    stopLoading?(): void;
}

const MainContent: FunctionalComponent<MainContentProps> = ({
    apiBasePath,
    selectedAreas,
    onUpdate,
    onAddressesFound,
    onShowAddresses,
    pricePerAddress,
    countryCode,
    isLoading,
    startLoading,
    stopLoading,
}: MainContentProps) => {
    const [areas, setAreas] = useState<MvArea[]>(selectedAreas);

    useEffect(() => {
        setAreas(selectedAreas);
    }, [selectedAreas]);

    const addArea = () =>
        setAreas((areas) => [
            ...areas,
            {
                id: uuidv4(),
                inputParams: defaultInputParams,
                result: null,
                disabled: false,
                isSelected: false,
                selectedAddresses: [],
            },
        ]);

    const handleAddressesFound = (
        areaId: string,
        addresses: MvAdressResult[]
    ) => {
        const newAreas = [
            ...areas.map((a) => {
                if (a.id === areaId) {
                    // Prevent duplication of addresses by filtering out all addresses that are already selected on other areas
                    const otherAddresses = areas.filter((a) => a.id !== areaId);
                    let existingAddresses: MvAdressResult[] = [];
                    if (otherAddresses.length > 0) {
                        otherAddresses.forEach((a) => {
                            existingAddresses = [
                                ...existingAddresses,
                                ...a.selectedAddresses,
                            ];
                        });
                    }
                    return {
                        ...a,
                        selectedAddresses: addresses.filter(
                            (address) =>
                                !existingAddresses.find(
                                    (ea) => ea.id === address.id
                                )
                        ),
                    };
                } else {
                    return a;
                }
            }),
        ];
        setAreas(newAreas);
        onAddressesFound(newAreas);
    };

    return (
        <div className={styles.mvWrapper}>
            <h1 className={styles.mvTitle}>
                <Text id="general.labels.areas">Areas</Text>
            </h1>

            {areas.map((a: MvArea) => (
                <Fragment key={a.id}>
                    <Area
                        addressData={a}
                        isDisabled={a.disabled}
                        apiBasePath={apiBasePath}
                        isSelected={a.isSelected}
                        onChange={(areaId: string, data: MvAdressResult) => {
                            const newAreas = [
                                ...areas.map((a) => {
                                    if (a.id === areaId) {
                                        return { ...a, result: data };
                                    } else {
                                        return a;
                                    }
                                }),
                            ];
                            setAreas(newAreas);
                            onUpdate(newAreas);
                        }}
                        onRadiusChange={(areaId: string, radius: number) => {
                            const newAreas = [
                                ...areas.map((a) => {
                                    if (a.id === areaId) {
                                        return {
                                            ...a,
                                            inputParams: {
                                                ...a.inputParams,
                                                radius,
                                            },
                                        };
                                    } else {
                                        return a;
                                    }
                                }),
                            ];
                            setAreas(newAreas);
                            onUpdate(newAreas);
                        }}
                        onMinMaxValueChange={(
                            areaId: string,
                            value: number[]
                        ) => {
                            const newAreas = [
                                ...areas.map((a) => {
                                    if (a.id === areaId) {
                                        return {
                                            ...a,
                                            inputParams: {
                                                ...a.inputParams,
                                                minValue: value[0],
                                                maxValue: value[1],
                                            },
                                        };
                                    } else {
                                        return a;
                                    }
                                }),
                            ];
                            setAreas(newAreas);
                        }}
                        onRemove={(area: MvArea) => {
                            const newAreas = [
                                ...areas.filter((a) => a.id !== area.id),
                            ];
                            setAreas(newAreas);
                            onUpdate(newAreas);
                        }}
                        onSelect={(areaId: string) => {
                            const newAreas = [
                                ...areas.map((a) => {
                                    if (a.id === areaId) {
                                        return { ...a, isSelected: true };
                                    } else {
                                        return { ...a, isSelected: false };
                                    }
                                }),
                            ];
                            setAreas(newAreas);
                            onUpdate(newAreas);
                        }}
                        onAddressesFound={handleAddressesFound}
                        onShowAddresses={onShowAddresses}
                        pricePerAddress={pricePerAddress}
                        countryCode={countryCode}
                        startLoading={startLoading}
                        stopLoading={stopLoading}
                    />
                </Fragment>
            ))}

            <Button
                type="primary"
                className={styles.mvAddAreaButton}
                outline
                disabled={isLoading}
                onClick={addArea}
            >
                <PlusIcon className={styles.mvPlusIcon} fill={"currentColor"} />
                <Text id="general.buttons.addArea">Add area</Text>
            </Button>
        </div>
    );
};

export default MainContent;
