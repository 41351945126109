import { Fragment, h } from "preact";
import { Text, useText } from "preact-i18n";

import styles from "./style.scss";
import LoadingIcon from "../../icons/LoadingIcon";

const AreaInputForm = ({
    addressId,
    setPostalCode,
    setStreetName,
    setHouseNumber,
    postalCode,
    houseNumber,
    isDisabled,
    isFetching,
    countryCode,
    streetName,
}: {
    addressId: string;
    setPostalCode(value: string): void;
    setStreetName(value: string): void;
    setHouseNumber(value: string): void;
    postalCode: string;
    houseNumber: string;
    isDisabled: boolean;
    isFetching: boolean;
    countryCode: string | undefined;
    streetName: string;
}) => {
    const {
        zipcodePlaceholder,
        streetNamePlaceholder,
        houseNumberPlaceholder,
    } = useText({
        zipcodePlaceholder: "general.labels.zipcodePlaceholder",
        streetNamePlaceholder: "general.labels.streetNamePlaceholder",
        houseNumberPlaceholder: "general.labels.houseNumberPlaceholder",
    });

    return (
        <Fragment>
            <div className={styles.mvAreaFormItem}>
                <label htmlFor={`postalCode-${addressId}`}>
                    <sup>*</sup>
                    <Text id="general.labels.zipcode">ZIP code</Text>
                </label>
                <input
                    value={postalCode}
                    placeholder={zipcodePlaceholder}
                    name={`postalCode-${addressId}`}
                    id={`postalCode-${addressId}`}
                    disabled={isDisabled || isFetching}
                    onChange={(e) =>
                        setPostalCode((e.target as HTMLInputElement).value)
                    }
                />
                {isFetching && (
                    <LoadingIcon
                        fill={"#00000080"}
                        className={styles.mvLoadingIcon}
                    />
                )}
            </div>
            {countryCode !== "nl" && (
                <div className={styles.mvAreaFormItem}>
                    <label htmlFor={`streetName-${addressId}`}>
                        <sup>*</sup>
                        <Text id="general.labels.streetName">Street name</Text>
                    </label>
                    <input
                        value={streetName}
                        placeholder={streetNamePlaceholder}
                        name={`streetName-${addressId}`}
                        id={`streetName-${addressId}`}
                        disabled={isDisabled || isFetching}
                        onChange={(e) =>
                            setStreetName((e.target as HTMLInputElement).value)
                        }
                    />
                    {isFetching && (
                        <LoadingIcon
                            fill={"#00000080"}
                            className={styles.mvLoadingIcon}
                        />
                    )}
                </div>
            )}
            <div className={styles.mvAreaFormItem}>
                <label htmlFor={`houseNumber-${addressId}`}>
                    <sup>*</sup>
                    <Text id="general.labels.houseNumber">House no</Text>
                </label>
                <input
                    value={houseNumber}
                    placeholder={houseNumberPlaceholder}
                    name={`houseNumber-${addressId}`}
                    id={`houseNumber-${addressId}`}
                    disabled={isDisabled || isFetching}
                    onChange={(e) =>
                        setHouseNumber((e.target as HTMLInputElement).value)
                    }
                />
                {isFetching && (
                    <LoadingIcon
                        fill={"#14456b"}
                        className={styles.mvLoadingIcon}
                    />
                )}
            </div>
        </Fragment>
    );
};

export default AreaInputForm;
