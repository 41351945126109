import { h, FunctionalComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import CloseIcon from "../../icons/CloseIcon";
import RangeSlider from "../RangeSlider";
import styles from "./style.scss";
import { usePrevious, useDebounce } from "react-use";
import { getLocations } from "../../services/api";
import ArrowIcon from "../../icons/ArrowIcon";
import LoadingIcon from "../../icons/LoadingIcon";
import EditIcon from "../../icons/EditIcon";
import MvAdressResult from "../../models/MvAddressResult";
import { useClickInside } from "../../services/helpers";
import Button from "../Button";
import { Text, useText } from "preact-i18n";
import AreaTypeFilters from "../AreaTypeFilters";
import AreaFetchResult from "../AreaFetchResult";
import AreaInputForm from "../AreaInputForm";
import MvInputParams from "../../models/MvInputParams";
import { Option } from "react-multi-select-component";

export interface AreaProps {
    addressData?: any;
    isDisabled?: boolean;
    apiBasePath?: string;
    isSelected?: boolean;
    onRemove?(adress: any): void;
    onChange(addressId: string, address: any): void;
    onSelect(addressId: string): void;
    onRadiusChange(addressId: string, radius: number): void;
    onAddressesFound(addressId: string, addresses: MvAdressResult[]): void;
    onMinMaxValueChange(addressId: string, value: number[]): void;
    onShowAddresses(): void;
    pricePerAddress: number;
    countryCode?: "nl" | "de" | "be" | "fr" | "en";
    startLoading?(): void;
    stopLoading?(): void;
}

interface ReqParams {
    c: "address" | "objects";
    housenumber?: number;
    zipcode?: string;
    limit?: number;
    distance?: number;
    housenumber_extension_letter?: string | undefined;
    housenumber_extension_number?: number | undefined;
    latitude?: number;
    priceFrom?: number;
    priceTo?: number;
    longitude?: number;
    countryCode?: "nl" | "de" | "be" | "fr" | "en";
    streetName?: string;
    rentOrBuy?: string; // "huur|koop" or "huur" or "koop"
    houseType?: string;
}

const Area: FunctionalComponent<AreaProps> = ({
    addressData = null,
    isDisabled = false,
    apiBasePath = "",
    isSelected,
    onRemove,
    onChange,
    onSelect,
    onRadiusChange,
    onAddressesFound,
    onMinMaxValueChange,
    onShowAddresses,
    pricePerAddress,
    countryCode,
    startLoading,
    stopLoading,
}: AreaProps) => {
    const [currentAddress, setCurrentAddress] = useState<MvAdressResult>(
        addressData.result ? addressData.result : null
    );
    const [error, setError] = useState<string>("");
    const [isOpen, setIsOpen] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showForm, setShowForm] = useState(addressData.result ? false : true);
    const [postalCode, setPostalCode] = useState(
        addressData.inputParams.postalCode
            ? addressData.inputParams.postalCode
            : addressData.result?.postcode
            ? addressData.result?.postcode
            : ""
    );
    const [streetName, setStreetName] = useState(
        addressData.inputParams.streetName
            ? addressData.inputParams.streetName
            : addressData.result?.straatnaam
            ? addressData.result?.straatnaam
            : ""
    );
    const [houseNumber, setHouseNumber] = useState(
        addressData.inputParams.houseNumber
            ? addressData.inputParams.houseNumber
            : addressData.result?.huisnr
            ? addressData.result?.huisnr
            : null
    );
    const prevPostalCode = usePrevious(postalCode);
    const prevHouseNumber = usePrevious(houseNumber);
    const prevStreetName = usePrevious(streetName);
    const [showRental, setShowRental] = useState(
        addressData.inputParams.getRental || false
    );
    const [showSale, setShowSale] = useState(
        addressData.inputParams.getSale || false
    );
    const [minValue, setMinValue] = useState(
        addressData.inputParams.minValue || 100
    );
    const [maxValue, setMaxValue] = useState(
        addressData.inputParams.maxValue || 500
    );
    const [houseType, setHouseType] = useState<Option[]>([]);
    const [radius, setRadius] = useState(addressData.inputParams.radius || 400);
    const [fetchResults, setFetchResults] = useState<MvAdressResult[]>([]);
    const [selected, setSelected] = useState(addressData.isSelected);
    const prevSelected = usePrevious(selected);

    const clickRef = useRef<any>();

    const notFoundSingle = useText("general.messages.addressNotFound.singular");
    const { addressNotFoundForSelected } = useText(
        "general.messages.addressNotFoundForSelected"
    );
    const { addressesNotFoundForSelection } = useText(
        "general.messages.addressesNotFoundForSelection"
    );
    const { radiusLabel, houseValueLabel } = useText({
        radiusLabel: "general.labels.radiusLabel",
        houseValueLabel: "general.labels.houseValueLabel",
    });

    let abortCtrl = new AbortController();

    const [, cancel] = useDebounce(
        async () => {
            if (
                houseNumber &&
                postalCode &&
                // For 'nl' it's not required to send streetName
                (countryCode === "nl" || streetName) &&
                (prevHouseNumber !== houseNumber ||
                    prevPostalCode !== postalCode ||
                    prevStreetName !== streetName)
            ) {
                const params: ReqParams = {
                    c: "address",
                    housenumber: houseNumber,
                    zipcode: postalCode,
                    countryCode: countryCode,
                };

                if (countryCode !== "nl") {
                    if (streetName) {
                        params.streetName = streetName;
                    }
                }
                await getLocation(params);
            }
        },
        500,
        [postalCode, houseNumber, streetName]
    );

    useClickInside(clickRef, () => {
        if (!selected) {
            setSelected(true);
            onSelect(addressData.id);
        }
    });

    useEffect(() => {
        if (prevSelected !== isSelected) {
            setSelected(isSelected);
        }
    }, [isSelected]);

    useEffect(() => {
        if (isFetching && startLoading) {
            startLoading();
        } else {
            stopLoading && stopLoading();
        }
    }, [isFetching]);

    // When address is dragged in the map, the results gets updated and needs to be reset here from the prop
    useEffect(() => {
        if (addressData?.result?.id !== currentAddress?.id) {
            setCurrentAddress(addressData.result);
            setPostalCode(addressData.result.postcode);
            setStreetName(addressData.result.straatnaam);
            setHouseNumber(addressData.result.huisnr);
        }
    }, [addressData.result]);

    useEffect(() => {
        abortCtrl = new AbortController();
        const fetchLocation = async () => {
            try {
                // Get location and initialize
                const params: ReqParams = {
                    c: "address",
                    housenumber: houseNumber,
                    zipcode: postalCode,
                };
                if (addressData.inputParams.houseNumberExtensionLetter) {
                    params.housenumber_extension_letter =
                        addressData.inputParams.houseNumberExtensionLetter;
                }
                if (addressData.inputParams.houseNumberExtensionNumber) {
                    params.housenumber_extension_number =
                        addressData.inputParams.houseNumberExtensionNumber;
                }
                if (addressData.inputParams.countryCode) {
                    params.countryCode = addressData.inputParams.countryCode;
                }
                if (addressData.inputParams.streetName) {
                    params.streetName = addressData.inputParams.streetName;
                }
                await getLocation(params, true, abortCtrl.signal);
            } catch (e) {
                console.log(e);
            }
        };
        if (!currentAddress && postalCode && houseNumber) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            fetchLocation();
        }
        return () => {
            abortCtrl.abort();
        };
    }, []);

    /**
     * Ceck if address is NL or in other countries
     * Other countries do not have residence type filters
     */
    const checkTypeFiltersEnabled = () => {
        return countryCode !== "nl" ? false : true;
    };

    const showTypeFilters = checkTypeFiltersEnabled();

    const getLocation = async (
        params: ReqParams,
        initialize?: boolean,
        signal?: any
    ) => {
        setError("");
        setIsFetching(true);
        setFetchResults([]);
        try {
            const {
                data: { data },
            } = await getLocations(apiBasePath, params, signal);
            if (!data.length) {
                if (initialize) {
                    setError(
                        `${addressNotFoundForSelected} ${params.zipcode}, ${
                            params.housenumber
                        }${
                            params.housenumber_extension_letter
                                ? `${params.housenumber_extension_letter}${
                                      params.housenumber_extension_number || ""
                                  }`
                                : ""
                        }`
                    );
                    setIsFetching(false);
                    setShowForm(true);
                    return;
                } else {
                    setError(notFoundSingle.singular);
                }
            }
            // If this is the first address to initialize the application, set this directly
            if (initialize) {
                setCurrentAddress(data[0]);
                setShowForm(false);
                setSelected(true);
                handleSelect();
            } else {
                setFetchResults(data);
            }
            setIsFetching(false);
        } catch (e: any) {
            setIsFetching(false);
            setError(e.message || notFoundSingle.singular);
        }
    };

    const getLocationAddresses = async (inputParams: MvInputParams) => {
        abortCtrl = new AbortController();
        setError("");
        setIsFetching(true);
        // setFetchResults([]);
        const {
            lat,
            long,
            distance,
            priceFrom,
            priceTo,
            countryCode,
            streetName,
            houseType,
            rentOrBuy,
        } = inputParams;

        let params: ReqParams = {
            c: "objects",
            latitude: lat,
            longitude: long,
            limit: 999999,
            countryCode: countryCode || "nl",
        };

        if (distance) {
            // Api handles distance in km not m
            params.distance = distance / 1000;
        }

        if (countryCode === "nl") {
            params = {
                ...params,
                priceFrom,
                priceTo,
            };
            if (rentOrBuy) {
                params.rentOrBuy = rentOrBuy;
            }
            if (houseType && houseType.length > 0) {
                params.houseType = houseType.map((t: any) => t.value).join("|");
            }
        }

        if (streetName) {
            params.streetName = streetName;
        }

        try {
            const {
                data: { data },
            } = await getLocations(apiBasePath, params, abortCtrl.signal);
            onAddressesFound(addressData.id, data);
            setIsFetching(false);
        } catch (e: any) {
            setIsFetching(false);
            setError(e.message || addressesNotFoundForSelection);
        }
    };

    const getAddresses = async (radius: number) => {
        if (!currentAddress) {
            return;
        }
        const params: MvInputParams = {
            lat: currentAddress.breedtegraad,
            long: currentAddress.lengtegraad,
            priceFrom: minValue * 1000,
            priceTo: maxValue * 1000,
            distance: radius,
            countryCode: countryCode,
            houseType: houseType,
        };

        if (countryCode === "nl") {
            if (showRental) {
                params.rentOrBuy = "huur";
            }
            if (showSale) {
                params.rentOrBuy = showRental
                    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      `${params.rentOrBuy}|koop`
                    : "koop";
            }
        }
        await getLocationAddresses(params);
    };

    const [, cancelSearch] = useDebounce(
        () => {
            getAddresses(radius);
        },
        500,
        [
            radius,
            currentAddress,
            maxValue,
            minValue,
            showRental,
            showSale,
            houseType,
        ]
    );

    const handleSelect = () => {
        setSelected(addressData);
        onSelect(addressData.id);
    };

    const handleRemove = () => {
        if (onRemove) {
            cancel(); // cancel getAddressesDebounce
            cancelSearch();
            if (abortCtrl) {
                abortCtrl.abort("removed");
            }
            onRemove(addressData);
        }
    };

    useEffect(() => {
        onChange(addressData.id, currentAddress);
    }, [currentAddress]);

    return (
        <div
            className={`${styles.mvWrapper} ${
                isDisabled ? styles.disabled : ""
            } ${isOpen ? styles.open : ""} ${selected ? styles.selected : ""}`}
            ref={clickRef}
        >
            {!isDisabled && (
                <button
                    type="button"
                    disabled={isFetching}
                    className={styles.mvAreaRemoveButton}
                    title="close"
                    onClick={handleRemove}
                >
                    <CloseIcon />
                </button>
            )}
            <div className={styles.mvTopBar}>
                <button
                    type="button"
                    disabled={isFetching}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <ArrowIcon
                        className={`${styles.mvArrowIcon} ${
                            isOpen && styles.open
                        }`}
                        fill={"currentColor"}
                    />
                    {currentAddress ? (
                        <span>
                            {currentAddress.straatnaam} {currentAddress.huisnr}
                            {currentAddress.huisnr_bag_letter
                                ? `${currentAddress.huisnr_bag_letter}${
                                      currentAddress.huisnr_bag_toevoeging || ""
                                  }`
                                : ""}
                            , {currentAddress.postcode}{" "}
                            {currentAddress.plaatsnaam}
                        </span>
                    ) : (
                        <span>
                            <Text id="general.buttons.newAddress">
                                New address
                            </Text>
                        </span>
                    )}
                </button>

                {currentAddress && !isDisabled && (
                    <button
                        type="button"
                        disabled={isFetching}
                        onClick={() => {
                            if (!isOpen || !showForm) {
                                setIsOpen(true);
                                setShowForm(true);
                            } else {
                                setShowForm(!showForm);
                            }
                        }}
                        className={styles.mvEditButton}
                    >
                        <EditIcon fill={"currentColor"} />
                    </button>
                )}
            </div>

            {showForm && (
                <div className={styles.mvAreaFormWrapper}>
                    <AreaInputForm
                        addressId={addressData.id}
                        postalCode={postalCode}
                        streetName={streetName}
                        houseNumber={houseNumber}
                        isFetching={isFetching}
                        countryCode={countryCode}
                        isDisabled={isDisabled}
                        setHouseNumber={setHouseNumber}
                        setPostalCode={setPostalCode}
                        setStreetName={setStreetName}
                    />
                    {fetchResults.length > 0 && (
                        <div className={styles.mvFetchResultsContainer}>
                            <h3>
                                <Text
                                    id="general.labels.addressFound"
                                    plural={fetchResults.length}
                                >
                                    Addresses found
                                </Text>
                                :
                            </h3>
                            <ul className={styles.mvFetchResults}>
                                {fetchResults.map((result: MvAdressResult) => {
                                    return (
                                        <AreaFetchResult
                                            key={
                                                result.bag_adresseerbaarobjectid
                                            }
                                            result={result}
                                            onClick={(res) => {
                                                setCurrentAddress(res);
                                                setFetchResults([]);
                                                setShowForm(false);
                                                setSelected(true);
                                                handleSelect();
                                            }}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}

            {error && <span className={styles.mvAreaError}>{error}</span>}

            <div className={styles.mvAreaFilters}>
                {isFetching && (
                    <div className={styles.mvLoadingOverlay}>
                        <LoadingIcon
                            fill={"#14456b"}
                            className={styles.mvLoadingIcon}
                        />
                    </div>
                )}
                <div className={styles.mvAreaSlider}>
                    <RangeSlider
                        label={radiusLabel}
                        minValue={10}
                        maxValue={countryCode === "nl" ? 1000 : 400}
                        value={radius}
                        onChange={(val: number) => {
                            setRadius(val);
                            onRadiusChange(addressData.id, val);
                        }}
                        isSingle
                        hasInputs
                    />

                    {showTypeFilters && (
                        <RangeSlider
                            label={houseValueLabel}
                            value={[minValue, maxValue]}
                            minValue={10}
                            maxValue={countryCode === "nl" ? 2000 : 300}
                            onChange={(val: number[]) => {
                                setMinValue(val[0]);
                                setMaxValue(val[1]);
                                onMinMaxValueChange(addressData.id, val);
                            }}
                            hasInputs
                        />
                    )}
                </div>
                {showTypeFilters && (
                    <AreaTypeFilters
                        isOpen={isOpen}
                        showRental={showRental}
                        showSale={showSale}
                        houseType={houseType}
                        setHouseType={setHouseType}
                        setShowRental={setShowRental}
                        setShowSale={setShowSale}
                        addressId={addressData.id}
                    />
                )}
            </div>

            <div className={styles.mvAreaAddresses}>
                <span>
                    €{" "}
                    {Number(
                        addressData.selectedAddresses.length * pricePerAddress
                    ).toFixed(2)}
                </span>
                <Button
                    type="link"
                    disabled={addressData.selectedAddresses.length === 0}
                    onClick={onShowAddresses}
                >
                    {addressData.selectedAddresses.length}{" "}
                    <Text
                        id="general.labels.addresses"
                        plural={addressData.selectedAddresses.length}
                    >{`adres${
                        addressData.selectedAddresses.length === 1 ? "" : "sen"
                    }`}</Text>
                </Button>
            </div>
        </div>
    );
};

export default Area;
