import { h } from "preact";
import { Text } from "preact-i18n";

import styles from "./style.scss";
import MvAdressResult from "../../models/MvAddressResult";
import MapMarker from "../../icons/MapMarker";

const AreaFetchResult = ({
    result,
    onClick,
}: {
    result: MvAdressResult;
    onClick(result: MvAdressResult): void;
}) => {
    return (
        <button
            className={styles.mvFetchResult}
            key={result.bag_adresseerbaarobjectid}
            type="button"
            onClick={() => onClick(result)}
        >
            <MapMarker className={styles.mvFetchMarker} fill="currentColor" />
            {result.straatnaam} {result.huisnr}
            {result.huisnr_bag_letter}, {result.postcode} {result.plaatsnaam}
            <span className={styles.mvAreaUseFetchResultButton}>
                <Text id="general.buttons.useAddress">Use</Text>
            </span>
        </button>
    );
};

export default AreaFetchResult;
