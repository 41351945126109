import { h, FunctionalComponent } from "preact";

export interface CloseIconProps {
    fill?: string;
    className?: any;
}

const CloseIcon: FunctionalComponent<CloseIconProps> = ({
    fill = "#CB0000",
    className,
}: CloseIconProps) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 9"
        className={className}
    >
        <path
            d="M7.765 7.176a.821.821 0 0 1 0 1.15.79.79 0 0 1-1.13 0L4 5.648 1.366 8.324a.787.787 0 0 1-1.132 0 .821.821 0 0 1 0-1.15L2.87 4.5.234 1.824a.821.821 0 0 1 0-1.15.791.791 0 0 1 1.132 0L4 3.353 6.635.676a.791.791 0 0 1 1.131 0 .821.821 0 0 1 0 1.15L5.131 4.5l2.634 2.675Z"
            className="addMarkerPaths"
            fill={fill}
        />
    </svg>
);

export default CloseIcon;
