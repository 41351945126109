import { h, render } from "preact";
import App from "./components/App";
import MvAddressSelectorSDKOptions from "./models/MvAddressSelectorSDKOptions";

export interface MvRenderProps {
    options: MvAddressSelectorSDKOptions;
    el: Element | HTMLElement;
    merge?: Element;
}

export interface MvInitProps {
    options: MvAddressSelectorSDKOptions;
    element: any;
    setOptions(changedOptions: any): MvAddressSelectorSDKOptions;
    tearDown(): any;
}

/**
 * Renders the MV AddressSelector component
 * @param  {Object} options: MvAddressSelectorSDKOptions configuration options
 * @param  {DOMElement} el:      target element where te component needs to be rendered
 * @param  {DOMElement} merge:   original element from first render (needed by preact for a rerender)
 * @return {DOMElement}          returns element from render
 */
const mvRender = ({ options, el, merge }: MvRenderProps) =>
    render(<App options={options} />, el, merge);

const sdkConfigDefaults: MvAddressSelectorSDKOptions = {
    useModal: true,
    isModalOpen: true,
    shouldCloseOnOverlayClick: false,
    containerId: "mv-address-selector",
    apiBasePath: "http://localhost:3000",
    language: "en",
    pricePerAddress: 0.2,
    messages: null,
    onError: (error: { message: string; type: string }) => {
        console.error(error.message);
    },
    onCancel: (data: any) => {
        console.error(data);
    },
    onComplete: (data: any) => {
        console.error(data);
    },
    onModalRequestClose: () => {
        console.log("request to close!");
    }, // do something
};

export const init = (opts: MvAddressSelectorSDKOptions): MvInitProps => {
    const options = {
        ...sdkConfigDefaults,
        ...opts,
    };

    const containerEl: Element | HTMLElement = document.getElementById(
        options.containerId
    )!;
    const element = mvRender({ options, el: containerEl });

    return {
        options,
        element,
        /**
         * Merges the original options with the new ones
         * @param {Object} changedOptions the new options object
         */
        setOptions(changedOptions: MvAddressSelectorSDKOptions) {
            this.options = { ...this.options, ...changedOptions };
            this.element = mvRender({
                options: this.options,
                el: containerEl,
                merge: this.element,
            });
            return this.options;
        },
        tearDown() {
            render(null, containerEl, this.element);
        },
    };
};
