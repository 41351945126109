import { h } from "preact";
import { Text, useText } from "preact-i18n";
import styles from "./style.scss";
import { houseTypeList } from "../../services/helpers";
import { MultiSelect, Option } from "react-multi-select-component";

interface AreaTypeFiltersProps {
    isOpen: boolean;
    showRental: boolean;
    showSale: boolean;
    houseType: Option[];
    setHouseType(value: Option[]): void;
    setShowRental(checked: boolean): void;
    setShowSale(checked: boolean): void;
    addressId?: string;
}

const AreaTypeFilters = ({
    isOpen,
    addressId = "",
    houseType = [],
    showRental,
    showSale,
    setHouseType,
    setShowRental,
    setShowSale,
}: AreaTypeFiltersProps) => {
    const selectTranslations = useText({
        allItemsAreSelected: "general.labels.allTypes",
        clearSearch: "general.labels.clearSearch",
        clearSelected: "general.labels.clearSelected",
        noOptions: "general.labels.noOptions",
        search: "general.labels.search",
        selectAll: "general.labels.selectAll",
        selectAllFiltered: "general.labels.selectAllFiltered",
        selectSomeItems: "general.labels.selectSomeItems",
        create: "general.labels.create",
    });
    /**
     * On value change set other value to false
     * We currently support only 1 value
     */
    const handleCheckChange = (type: "rental" | "sale", checked: boolean) => {
        switch (type) {
            case "rental":
                setShowRental(checked);
                break;
            case "sale":
                setShowSale(checked);
                break;
        }
    };

    const handleHouseTypeChange = (value: string[]) => {
        setHouseType(value);
    };

    return (
        <div
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`${styles.mvTypeFiltersContainer} ${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                isOpen ? styles.open : ""
            }`}
        >
            <div className={styles.mvTypeFilterWrapper}>
                <label htmlFor={`houseType-${addressId}`}>
                    <Text id="general.labels.houseType">House type</Text>
                </label>
                <div className={styles.mvTypeFilters}>
                    <MultiSelect
                        options={houseTypeList}
                        value={houseType}
                        overrideStrings={selectTranslations}
                        hasSelectAll={false}
                        disableSearch
                        onChange={handleHouseTypeChange}
                        labelledBy={`Select-houseType-${addressId}`}
                    />
                    {/* <select
                        id={`houseType-${addressId}`}
                        name={`houseType-${addressId}`}
                        value={houseType}
                        onChange={(e) => {
                            handleHouseTypeChange(
                                (e.target as HTMLSelectElement).value
                            );
                        }}
                    >
                        <option value={""}>
                            <Text id="general.labels.allTypes">All types</Text>
                        </option>
                        {houseTypes.map((ht) => {
                            return (
                                <option value={ht.value} key={ht.value}>
                                    <Text id={`general.houseTypes.${ht.value}`}>
                                        {ht.label}
                                    </Text>
                                </option>
                            );
                        })}
                    </select> */}
                </div>
            </div>
            <div className={styles.mvTypeFilterWrapper}>
                <label htmlFor="propertyType">
                    <Text id="general.labels.filterOn">Filter on</Text>
                </label>
                <div className={styles.mvTypeFilters}>
                    <label htmlFor={`propertyRental-${addressId}`}>
                        <input
                            type="checkbox"
                            id={`propertyRental-${addressId}`}
                            name={`propertyRental-${addressId}`}
                            checked={showRental}
                            onChange={(e) => {
                                handleCheckChange(
                                    "rental",
                                    (e.target as HTMLInputElement).checked
                                );
                            }}
                        />
                        <span>
                            <Text id="general.labels.filterRentalOption">
                                Rental
                            </Text>
                        </span>
                    </label>
                    <label htmlFor={`propertySale-${addressId}`}>
                        <input
                            type="checkbox"
                            id={`propertySale-${addressId}`}
                            name={`propertySale-${addressId}`}
                            checked={showSale}
                            onChange={(e) => {
                                handleCheckChange(
                                    "sale",
                                    (e.target as HTMLInputElement).checked
                                );
                            }}
                        />
                        <span>
                            <Text id="general.labels.filterSaleOption">
                                Sale
                            </Text>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default AreaTypeFilters;
