import { h, FunctionalComponent } from "preact";

export interface EditIconProps {
    fill?: string;
    className?: string;
}

const EditIcon: FunctionalComponent<EditIconProps> = ({
    fill = "#CB0000",
    className,
}: EditIconProps) => (
    <svg
        width="17"
        height="17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M16.344 2.313 14.656.625a2.113 2.113 0 0 0-2.969 0L1.75 10.5 1 15.156c-.094.5.344.938.844.844l4.656-.75 9.875-9.938c.813-.812.813-2.156-.031-3ZM4.75 9.436l5.313-5.28 2.78 2.78-5.28 5.313v-1.313h-1.5v-1.5H4.75Zm-1.188 4.907-.906-.906.406-2.282.563-.594h1.312v1.5h1.5v1.313l-.593.563-2.282.406Zm11.844-10-1.531 1.531-2.75-2.75 1.531-1.531a.736.736 0 0 1 1.031 0l1.688 1.687c.313.313.313.781.031 1.063Z"
            fill={fill}
            fill-opacity=".5"
        />
    </svg>
);

export default EditIcon;
