import { h, FunctionalComponent } from "preact";
import { Text } from "preact-i18n";
import MvArea from "../../models/MvArea";
import { formatCurrencyValue } from "../../services/helpers";
import Button from "../Button";

import styles from "./style.scss";

export interface FooterProps {
    showAll(): void;
    addresses: MvArea[];
    pricePerAddress: number;
    onClose(): void;
    onNext(): void;
    isLoading: boolean;
}

const Footer: FunctionalComponent<FooterProps> = ({
    showAll,
    addresses,
    pricePerAddress,
    onClose,
    onNext,
    isLoading,
}: FooterProps) => {
    const addressCount = addresses
        .map((a) => a.selectedAddresses.length)
        .reduce((partialSum, a) => partialSum + a, 0);

    const totalPrice = addressCount * pricePerAddress;

    return (
        <footer className={styles.mvFooter}>
            <div className={styles.mvFooterLeft}>
                <div className={styles.mvFooterTotal}>
                    <span className={styles.mvFooterTotalLabel}>
                        <Text id="general.labels.total">Total</Text>
                    </span>
                    <span className={styles.mvFooterTotalCount}>
                        {formatCurrencyValue(totalPrice)}
                    </span>
                </div>
                <Button
                    type="link"
                    className={styles.mvAddressesTotal}
                    onClick={showAll}
                >
                    {addressCount}{" "}
                    <Text
                        id="general.buttons.addressesSelected"
                        plural={addressCount}
                    >{`adres${addressCount === 1 ? "" : "sen"} selected`}</Text>
                </Button>
            </div>
            <div className={styles.mvFooterRight}>
                <Button
                    onClick={onClose}
                    className={styles.mvCancelButton}
                    type="secondary"
                >
                    <Text id="general.buttons.cancel">Cancel</Text>
                </Button>
                <Button onClick={onNext} type="primary" disabled={isLoading}>
                    <Text id="general.buttons.next">Next</Text>
                </Button>
            </div>
        </footer>
    );
};

export default Footer;
