import { h, FunctionalComponent } from "preact";
import styles from "./style.scss";

export interface ButtonProps {
    children?: any;
    onClick?(): any;
    type?: string;
    outline?: boolean;
    className?: string;
    disabled?: boolean;
}

const bgColor = (type = "default") => {
    switch (type) {
        case "success":
            return "green";
        case "secondary":
            return "#C4C4C4";
        case "link":
            return "transparent";
        case "tertiary":
            return "#14456b";
        case "error":
        case "danger":
        case "cancel":
            return "#CB0000";
        case "default":
        case "primary":
        default:
            return "#F28E3D";
    }
};

const Button: FunctionalComponent<ButtonProps> = ({
    children,
    onClick,
    type = "default",
    outline = false,
    className,
    disabled = false,
}: ButtonProps) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className={`${styles.mvButton} ${styles[type]} ${
                outline ? styles.outline : ""
            } ${className}`}
            disabled={disabled}
            style={{
                "--bg-color": bgColor(type),
                color: type === "secondary" ? "#222222" : "#fff",
                borderColor: bgColor(type),
            }}
        >
            {children}
        </button>
    );
};

export default Button;
