import { h, FunctionalComponent } from "preact";

export interface ArrowIconProps {
    fill?: string;
    className?: string;
}

const ArrowIcon: FunctionalComponent<ArrowIconProps> = ({
    fill = "#CB0000",
    className,
}: ArrowIconProps) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 10"
        className={className}
    >
        <path
            d="M7.5 1.094a.736.736 0 0 0-1.031 0L.375 7.156c-.281.313-.281.782 0 1.063l.719.719c.281.28.75.28 1.062 0L7 4.125l4.813 4.813c.312.28.78.28 1.062 0l.719-.72c.281-.28.281-.75 0-1.062L7.5 1.094Z"
            fill={fill}
        />
    </svg>
);

export default ArrowIcon;
