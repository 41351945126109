import { h, FunctionalComponent, Fragment } from "preact";
import { Text } from "preact-i18n";
import { useEffect, useState } from "preact/hooks";
import { usePrevious } from "react-use";
import CloseIcon from "../../icons/CloseIcon";
import MvAdressResult from "../../models/MvAddressResult";
import MvArea from "../../models/MvArea";
import Button from "../Button";

import styles from "./style.scss";

export interface AsidePanelProps {
    isOpen: boolean;
    areas: MvArea[];
    isEditable: boolean;
    addressLimit?: number;
    zipCodeAreaMinCount?: number;
    onClose?(): void;
    onGroupsChange(groups: any[]): void;
    onConfirm(): void;
}

export interface StreetNameSelection {
    [zipcodeChars: string]: MvAdressResult[];
}

export interface AddressGroup extends MvArea {
    groupedByStreetName: StreetNameSelection;
    groupedByZipCodeAndStreetName: {
        [zipcodeChars: string]: StreetNameSelection[];
    };
}

export interface SelectedGroup {
    id: string;
    selectedStreetNames: string[];
}

const AsidePanel: FunctionalComponent<AsidePanelProps> = ({
    isOpen,
    areas,
    isEditable,
    addressLimit,
    zipCodeAreaMinCount,
    onClose,
    onGroupsChange,
    onConfirm,
}: AsidePanelProps) => {
    const getAddressGroups = (areas: MvArea[]) => {
        return areas
            .filter((a) => a.result)
            .map((a: MvArea) => {
                return {
                    ...a,
                    groupedByStreetName: a.selectedAddresses.reduce(
                        (r: StreetNameSelection, a: MvAdressResult) => {
                            r[a.straatnaam] = r[a.straatnaam] || [];
                            r[a.straatnaam].push(a);
                            return r;
                        },
                        {}
                    ),
                    groupedByZipCodeAndStreetName: a.selectedAddresses.reduce(
                        (
                            r:
                                | StreetNameSelection
                                | {
                                      [
                                          zipcodeChars: string
                                      ]: StreetNameSelection;
                                  },
                            a: MvAdressResult
                        ) => {
                            // The first 2 charachters of a zipcode are the zipcodeChars for a zipcode area
                            const zipCodeArea = a.postcode
                                ? String(a.postcode).substring(0, 2)
                                : "undefined";

                            // @ts-expect-error type collision but intended because we convert the object to something new
                            const newObj: {
                                [zipcodeChars: string]: StreetNameSelection;
                            } = r;
                            newObj[zipCodeArea] = newObj[zipCodeArea] || [];
                            newObj[zipCodeArea][a.straatnaam] =
                                newObj[zipCodeArea][a.straatnaam] || [];
                            newObj[zipCodeArea][a.straatnaam].push(a);
                            return newObj;
                        },
                        {}
                    ),
                };
            });
    };

    const hasZipCodeAreaLimit: number | undefined = zipCodeAreaMinCount;

    // @ts-expect-error conversion of objects has happened here
    const addressGroups: AddressGroup[] = getAddressGroups(areas);

    /**
     * Calculates the total amount of addresses within a zipcode area
     * Takes into account the 'selected addresses' state on the summary step
     * @param groupId
     * @param zipCodeAreayKey
     * @returns number
     */
    const getZipCodeAreaTotal = (groupId: string, zipCodeAreayKey: string) => {
        const addressG: AddressGroup | undefined = addressGroups.find(
            (ag) => ag.id === groupId
        );

        let count = 0;
        if (addressG) {
            Object.keys(
                addressG?.groupedByZipCodeAndStreetName[zipCodeAreayKey]
            ).forEach((s) => {
                count +=
                    addressG?.groupedByZipCodeAndStreetName[zipCodeAreayKey][s]
                        .length;
            });
        }
        return count;
    };

    /**
     * Helper function to get the total count of addresses per area in an object.
     * @param groups Address groups array
     * @param getInitial Boolean flag that indicates whether we need to look at the originally fetched addresses or the selected addresses (later on)
     * @returns Object
     */
    const getZipCodeAreaCounts = (
        groups: AddressGroup[],
        getInitial?: boolean
    ) => {
        const zipCodeCounts: { [key: string]: number } = {};
        groups.forEach((group: AddressGroup) => {
            Object.keys(group.groupedByZipCodeAndStreetName).forEach(
                (zipcode: string) => {
                    const total: number = getInitial
                        ? getZipCodeAreaTotal(group.id, zipcode)
                        : getZipCodeAreaSelectedTotal(group.id, zipcode);
                    zipCodeCounts[`${zipcode}`] = zipCodeCounts[`${zipcode}`]
                        ? zipCodeCounts[`${zipcode}`] + total
                        : total;
                }
            );
        });
        return zipCodeCounts;
    };

    const getMappedSelectedGroups = (groups: AddressGroup[]) => {
        const zipCodeCounts: { [key: string]: number } = getZipCodeAreaCounts(
            groups,
            true
        );

        return groups.map((a) => {
            let streetNames: string[] = [];

            if (hasZipCodeAreaLimit && zipCodeAreaMinCount) {
                const addresses = Object.keys(
                    a.groupedByZipCodeAndStreetName
                ).reduce((result, zipcode) => {
                    if (zipCodeCounts[zipcode] >= zipCodeAreaMinCount) {
                        return a.groupedByZipCodeAndStreetName[zipcode];
                    }
                    return result;
                }, []);
                Object.keys(addresses).forEach((streetName) => {
                    streetNames.push(streetName);
                });
            } else {
                streetNames = Object.keys(a.groupedByStreetName);
            }
            return {
                id: a.id,
                selectedStreetNames: streetNames,
            };
        });
    };

    const [selectedGroups, setSelectedGroups] = useState<SelectedGroup[]>(
        addressGroups ? getMappedSelectedGroups(addressGroups) : []
    );

    const previousSelectedGroups = usePrevious<SelectedGroup[]>(selectedGroups);

    useEffect(() => {
        const newAddressGroups = getAddressGroups(areas);
        setSelectedGroups(getMappedSelectedGroups(newAddressGroups));
    }, [areas]);

    const hasDifference = (prevGroups: any[], currentGroups: any[]) => {
        if (prevGroups.length !== currentGroups.length) {
            return true;
        }
        const groupsWithDifference = prevGroups.filter((g) => {
            const matchingCurrentGroup = currentGroups.find(
                (cg) => cg.id === g.id
            );
            return (
                g.selectedStreetNames.length !==
                matchingCurrentGroup.selectedStreetNames.length
            );
        });
        return groupsWithDifference.length > 0;
    };

    useEffect(() => {
        if (!previousSelectedGroups) {
            return;
        }
        const hasDiff = hasDifference(previousSelectedGroups, selectedGroups);
        if (hasDiff) {
            onGroupsChange(selectedGroups);
        }
    }, [selectedGroups, previousSelectedGroups]);

    const toggleGroup = (
        areaId: string,
        groupName: string,
        checked: boolean
    ) => {
        setSelectedGroups([
            ...selectedGroups.map((g) => {
                if (g.id !== areaId) {
                    return g;
                }
                return {
                    ...g,
                    selectedStreetNames: !checked
                        ? [
                              ...g.selectedStreetNames.filter(
                                  (k) => k !== groupName
                              ),
                          ]
                        : [...g.selectedStreetNames, groupName],
                };
            }),
        ]);
    };

    const toggleCheckAll = (areaId: string, checked: boolean) => {
        const addressGroupKeys = Object.keys(
            addressGroups.filter((a) => a.id === areaId)[0].groupedByStreetName
        );
        setSelectedGroups([
            ...selectedGroups.map((g) => {
                if (g.id !== areaId) {
                    return g;
                }
                return {
                    ...g,
                    selectedStreetNames: checked ? addressGroupKeys : [],
                };
            }),
        ]);
    };

    const addressCount =
        addressGroups &&
        addressGroups.length &&
        selectedGroups &&
        selectedGroups.length
            ? selectedGroups.reduce((acc, a) => {
                  const addressG = addressGroups.find((ag) => ag.id === a.id);
                  let count = 0;
                  a.selectedStreetNames.forEach((s) => {
                      count += addressG?.groupedByStreetName[s]
                          ? addressG?.groupedByStreetName[s].length
                          : 0;
                  });
                  return acc + count;
              }, 0)
            : 0;

    /**
     * Returns a boolean if all addresses within a group and zipCodeArea are selected
     * @param groupId String
     * @param zipCodeAreayKey Key of the zipcode area code
     * @returns boolean flag whether this is true/false
     */
    const isAllChecked = (groupId: string, zipCodeAreayKey: string) => {
        const addressG: AddressGroup | undefined = addressGroups.find(
            (ag) => ag.id === groupId
        );
        const currentGroup = selectedGroups.find((g) => g.id === groupId);
        return addressG && currentGroup
            ? Object.keys(
                  addressG.groupedByZipCodeAndStreetName[zipCodeAreayKey]
              ).length === currentGroup.selectedStreetNames.length
            : false;
    };

    const getZipCodeAreaSelectedTotal = (
        groupId: string,
        zipCodeAreayKey: string
    ) => {
        const addressG: AddressGroup | undefined = addressGroups.find(
            (ag) => ag.id === groupId
        );
        const selectedGroup: SelectedGroup | undefined = selectedGroups.find(
            (g) => g.id === groupId
        );

        let count = 0;
        if (addressG && selectedGroup) {
            Object.keys(
                addressG?.groupedByZipCodeAndStreetName[zipCodeAreayKey]
            ).forEach((s: string) => {
                // check if street name is included in selection
                if (selectedGroup?.selectedStreetNames.indexOf(s) >= 0) {
                    count +=
                        addressG?.groupedByZipCodeAndStreetName[
                            zipCodeAreayKey
                        ][s].length;
                }
            });
        }
        return count;
    };

    const isConfirmDisabled = () => {
        const limitExceeded = addressLimit
            ? addressCount > addressLimit
            : false;

        const hasSelectedGroups =
            selectedGroups.filter((g) => g.selectedStreetNames.length > 0)
                .length &&
            selectedGroups.filter((g) => g.selectedStreetNames.length > 0)[0];

        const hasNotReachedMinimumSelection = hasSelectionLimitError();
        return (
            areas.length === 0 ||
            !hasSelectedGroups ||
            limitExceeded ||
            hasNotReachedMinimumSelection
        );
    };

    /**
     * Checks whether the initial selection of addresses within the tool for each
     * group and area has reached the minimum quantity
     * @param groupId String
     * @param zipCodeAreayKey String
     * @returns true or false
     */
    const hasNotReachedSelectionMinimum = (zipCodeAreayKey: string) => {
        const zipCodeCounts: { [key: string]: number } = getZipCodeAreaCounts(
            addressGroups,
            true
        );

        return (
            zipCodeAreaMinCount !== undefined &&
            zipCodeCounts[zipCodeAreayKey] > 0 &&
            zipCodeCounts[zipCodeAreayKey] < zipCodeAreaMinCount
        );
    };

    /**
     * Checks whether the selection for a group and area has reached the minimum quantity or not
     * @param groupId String
     * @param zipCodeAreaKey String
     * @returns true or false
     */
    const selectionDidNotReachSelectionMinimum = (zipCodeAreaKey: string) => {
        const zipCodeCounts: { [key: string]: number } = getZipCodeAreaCounts(
            addressGroups,
            false
        );

        return (
            zipCodeAreaMinCount !== undefined &&
            zipCodeCounts[zipCodeAreaKey] > 0 &&
            zipCodeCounts[zipCodeAreaKey] < zipCodeAreaMinCount
        );
    };

    const hasSelectionLimitError = (): boolean => {
        let error = false;
        const zipCodeCounts: { [key: string]: number } = getZipCodeAreaCounts(
            addressGroups,
            false
        );

        if (!zipCodeAreaMinCount) {
            return error;
        }

        Object.keys(zipCodeCounts).forEach((zipcode: string) => {
            if (
                zipCodeCounts[`${zipcode}`] > 0 &&
                zipCodeCounts[`${zipcode}`] < zipCodeAreaMinCount
            ) {
                error = true;
            }
        });
        return error;
    };

    return (
        <aside className={`${styles.mvAside} ${isOpen ? styles.open : ""}`}>
            <div className={styles.mvAsidePanelHeader}>
                <h2>
                    <Text id="slideInPanel.header.title">Addresses</Text>
                </h2>
                {!isEditable && (
                    <button
                        type="button"
                        onClick={onClose}
                        className={styles.mvPanelCloseButton}
                    >
                        <CloseIcon
                            fill="currentColor"
                            className={styles.mvPanelCloseIcon}
                        />
                    </button>
                )}
            </div>
            <div className={styles.mvAsidePanelBody}>
                {addressGroups && addressGroups?.length > 0 ? (
                    addressGroups.map((group) => {
                        return (
                            <div
                                className={styles.mvAsidePanelAddressGroup}
                                key={group.id}
                            >
                                <h3>
                                    {group.result?.straatnaam}{" "}
                                    {group.result?.huisnr}
                                    {group.result?.huisnr_bag_letter
                                        ? `${group.result?.huisnr_bag_letter}${
                                              group.result
                                                  ?.huisnr_bag_toevoeging || ""
                                          }`
                                        : ""}
                                    , {group.result?.postcode}{" "}
                                    {group.result?.plaatsnaam}
                                </h3>

                                {Object.keys(
                                    group.groupedByZipCodeAndStreetName
                                ).length > 0 &&
                                    Object.keys(
                                        group.groupedByZipCodeAndStreetName
                                    ).map((areaKey, _val) => (
                                        <Fragment key={areaKey}>
                                            <table>
                                                <thead>
                                                    {Object.keys(
                                                        group
                                                            .groupedByZipCodeAndStreetName[
                                                            areaKey
                                                        ]
                                                    )?.length > 0 && (
                                                        <tr>
                                                            {isEditable && (
                                                                <th>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`selectAll-${group.id}`}
                                                                        name={`selectAll-${group.id}`}
                                                                        checked={isAllChecked(
                                                                            group.id,
                                                                            areaKey
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            toggleCheckAll(
                                                                                group.id,
                                                                                (
                                                                                    e.target as HTMLInputElement
                                                                                )
                                                                                    .checked
                                                                            )
                                                                        }
                                                                    />
                                                                </th>
                                                            )}
                                                            <th
                                                                className={
                                                                    styles.zipcodeArea
                                                                }
                                                            >
                                                                <Text id="slideInPanel.tableColumnLabels.zipCodeArea">
                                                                    Zipcode area
                                                                </Text>
                                                            </th>
                                                            <th>
                                                                <Text id="slideInPanel.tableColumnLabels.streetName">
                                                                    Street name
                                                                </Text>
                                                            </th>
                                                            <th>
                                                                <Text id="slideInPanel.tableColumnLabels.amountName">
                                                                    Amount
                                                                </Text>
                                                            </th>
                                                        </tr>
                                                    )}
                                                </thead>
                                                <tbody>
                                                    {Object.keys(
                                                        group
                                                            .groupedByZipCodeAndStreetName[
                                                            areaKey
                                                        ]
                                                    ).length > 0 ? (
                                                        <Fragment>
                                                            {Object.keys(
                                                                group
                                                                    .groupedByZipCodeAndStreetName[
                                                                    areaKey
                                                                ]
                                                            )
                                                                .sort((a, b) =>
                                                                    a.localeCompare(
                                                                        b
                                                                    )
                                                                )
                                                                .map((k) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                k
                                                                            }
                                                                        >
                                                                            {isEditable && (
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`selectGroup-${group.id}-${k}`}
                                                                                        name={`selectGroup-${group.id}-${k}`}
                                                                                        checked={
                                                                                            selectedGroups
                                                                                                .filter(
                                                                                                    (
                                                                                                        g
                                                                                                    ) =>
                                                                                                        g.id ===
                                                                                                        group.id
                                                                                                )[0]
                                                                                                .selectedStreetNames.indexOf(
                                                                                                    k
                                                                                                ) >
                                                                                            -1
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            toggleGroup(
                                                                                                group.id,
                                                                                                k,
                                                                                                (
                                                                                                    e.target as HTMLInputElement
                                                                                                )
                                                                                                    .checked
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            )}
                                                                            <td
                                                                                className={
                                                                                    styles.zipcodeArea
                                                                                }
                                                                            >
                                                                                {
                                                                                    areaKey
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    k
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    group
                                                                                        .groupedByZipCodeAndStreetName[
                                                                                        areaKey
                                                                                    ][
                                                                                        k
                                                                                    ]
                                                                                        .length
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            <tr>
                                                                {isEditable && (
                                                                    <td
                                                                        style={{
                                                                            minWidth:
                                                                                "22px",
                                                                        }}
                                                                    ></td>
                                                                )}
                                                                <td
                                                                    className={
                                                                        styles.zipcodeArea
                                                                    }
                                                                    style={{
                                                                        color: hasNotReachedSelectionMinimum(
                                                                            areaKey
                                                                        )
                                                                            ? "red"
                                                                            : "inherit",
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        <Text id="general.messages.total">
                                                                            Total
                                                                        </Text>
                                                                    </strong>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        color:
                                                                            hasNotReachedSelectionMinimum(
                                                                                areaKey
                                                                            ) ||
                                                                            selectionDidNotReachSelectionMinimum(
                                                                                areaKey
                                                                            )
                                                                                ? "red"
                                                                                : "inherit",
                                                                    }}
                                                                >
                                                                    {hasNotReachedSelectionMinimum(
                                                                        areaKey
                                                                    ) ? (
                                                                        <Text
                                                                            id="general.messages.zipCodeAreaLimitError"
                                                                            fields={{
                                                                                limit: zipCodeAreaMinCount,
                                                                            }}
                                                                        >
                                                                            {`At least
                                                                        ${zipCodeAreaMinCount}
                                                                        addresses
                                                                        should
                                                                        be
                                                                        selected
                                                                        per
                                                                        postcode
                                                                        area/route
                                                                        region. Adjust your search area by increasing the radius or adding a second selection within the guide region`}
                                                                        </Text>
                                                                    ) : null}
                                                                    {selectionDidNotReachSelectionMinimum(
                                                                        areaKey
                                                                    ) ? (
                                                                        <Text
                                                                            id="general.messages.zipCodeAreaSelectionLimitError"
                                                                            fields={{
                                                                                limit: zipCodeAreaMinCount,
                                                                            }}
                                                                        >
                                                                            {`At least
                                                                        ${zipCodeAreaMinCount}
                                                                        addresses
                                                                        should
                                                                        be
                                                                        selected
                                                                        per
                                                                        postcode
                                                                        area/route
                                                                        region.`}
                                                                        </Text>
                                                                    ) : null}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        color: hasNotReachedSelectionMinimum(
                                                                            areaKey
                                                                        )
                                                                            ? "red"
                                                                            : "inherit",
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        {getZipCodeAreaTotal(
                                                                            group.id,
                                                                            areaKey
                                                                        )}
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ) : (
                                                        <tr>
                                                            {isEditable && (
                                                                <td></td>
                                                            )}
                                                            <td>
                                                                <Text
                                                                    id="general.messages.addressNotFound"
                                                                    plural={2}
                                                                >
                                                                    No addresses
                                                                    found
                                                                </Text>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <span
                                                className={
                                                    styles.selectedMessage
                                                }
                                            >
                                                <Text id="general.labels.selected">
                                                    Selected
                                                </Text>
                                                :{" "}
                                                {getZipCodeAreaSelectedTotal(
                                                    group.id,
                                                    areaKey
                                                )}
                                            </span>
                                        </Fragment>
                                    ))}
                            </div>
                        );
                    })
                ) : (
                    <p>
                        <Text id="slideInPanel.content.noSelection">
                            Please select some addresses in the previous step
                        </Text>
                    </p>
                )}
            </div>

            <div className={styles.mvAsidePanelFooter}>
                {addressLimit && addressCount > addressLimit && (
                    <p className={styles.addressLimitMessage}>
                        <Text
                            id="general.messages.addressLimitExceeded"
                            fields={{
                                max: addressLimit,
                                count: addressCount - addressLimit,
                            }}
                            plural={addressCount - addressLimit}
                        >
                            {`You can only select ${addressLimit} addresses.${" "}
                            ${addressCount - addressLimit} addresses will not be
                            selected.`}
                        </Text>
                    </p>
                )}
                {isEditable ? (
                    <Button
                        type="primary"
                        className={styles.mvAsidePanelConfirmButton}
                        disabled={isConfirmDisabled()}
                        onClick={() => {
                            onConfirm();
                        }}
                    >
                        <Text id="general.buttons.confirmSelection">
                            Confirm selection
                        </Text>
                    </Button>
                ) : (
                    <Button type="secondary" outline onClick={onClose}>
                        <Text id="general.buttons.closeModal">Close</Text>
                    </Button>
                )}
            </div>
        </aside>
    );
};

export default AsidePanel;
