import nl from "./nl.json";
import en from "./en.json";
import de from "./de.json";
import fr from "./fr.json";

export const translations = {
    nl: nl,
    en: en,
    de: de,
    fr: fr,
};
