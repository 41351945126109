import { h, FunctionalComponent } from "preact";

export interface PlusIconProps {
    fill?: string;
    className?: any;
}

const PlusIcon: FunctionalComponent<PlusIconProps> = ({
    fill = "#F28E3D",
    className,
}: PlusIconProps) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
        className={className}
    >
        <path
            d="M13.5 6.737c0 .534-.448.967-1 .967H8v4.352c0 .535-.448.967-1 .967s-1-.432-1-.967V7.704H1.5c-.552 0-1-.433-1-.967 0-.535.448-.967 1-.967H6V1.418c0-.535.448-.967 1-.967s1 .432 1 .967V5.77h4.5c.553 0 1 .432 1 .967Z"
            fill={fill}
        />
    </svg>
);

export default PlusIcon;
