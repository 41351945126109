import { h, FunctionalComponent } from "preact";

export interface LongArrowIconProps {
    fill?: string;
    className?: string;
}

const LongArrowIcon: FunctionalComponent<LongArrowIconProps> = ({
    fill = "#CB0000",
    className,
}: LongArrowIconProps) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 13"
        className={className}
    >
        <path
            d="m7.262 12.71.547-.519c.136-.136.136-.355 0-.464L3.57 7.46h9.352a.333.333 0 0 0 .328-.328v-.766a.353.353 0 0 0-.328-.328H3.57L7.81 1.801c.136-.11.136-.328 0-.465l-.547-.52c-.11-.136-.328-.136-.465 0L1.082 6.531a.315.315 0 0 0 0 .465l5.715 5.715c.137.137.355.137.465 0Z"
            fill={fill}
        />
    </svg>
);

export default LongArrowIcon;
