import { useEffect } from "preact/hooks";
import MvInputParams from "../models/MvInputParams";
import { Option } from "react-multi-select-component";

export const useClickInside = (ref: any, callback: Function) => {
    const handleClick = (e: Event) => {
        if (ref.current && ref.current.contains(e.target)) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

export const defaultInputParams: MvInputParams = {
    postalCode: "",
    houseNumber: "",
    streetName: "",
    getRental: false,
    getSale: false,
    minValue: 300,
    maxValue: 500,
    radius: 400,
};

export const formatCurrencyValue = (value: number) => {
    return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
    }).format(value);
};

export const houseTypeList: Option[] = [
    { label: "Appartement", value: "Appartement" },
    { label: "Vrijstaande woning", value: "Vrijstaande woning" },
    { label: "Tussenwoning", value: "Tussenwoning" },
    { label: "Hoekwoning", value: "Hoekwoning" },
    { label: "2-onder-1-kapwoning", value: "2-onder-1-kapwoning" },
];
