import { h, FunctionalComponent } from "preact";
import CloseIcon from "../../icons/CloseIcon";
import LongArrowIcon from "../../icons/LongArrowIcon";
import { formatCurrencyValue } from "../../services/helpers";
import MvArea from "../../models/MvArea";
import Button from "../Button";
import { Text } from "preact-i18n";

import styles from "./style.scss";

export interface SummaryProps {
    areas: MvArea[];
    pricePerAddress: number;
    zipCodeAreaMinCount?: number;
    onClose?(): void;
    onAreaRemove?(area: MvArea): void;
}

const Summary: FunctionalComponent<SummaryProps> = ({
    areas,
    pricePerAddress,
    zipCodeAreaMinCount,
    onClose,
    onAreaRemove,
}: SummaryProps) => {
    const addressCount = areas
        .map((a) => a.selectedAddresses.length)
        .reduce((partialSum, a) => partialSum + a, 0);

    const totalPrice = addressCount * pricePerAddress;

    return (
        <div className={styles.mvSummary}>
            <div className={styles.mvSummaryHeader}>
                <h2 className={styles.mvSummaryTitle}>
                    <Text id="summary.header.title">Summary</Text>
                </h2>
            </div>
            <div className={styles.mvSummaryBody}>
                {areas
                    .filter((a) => a.result)
                    .map((a: MvArea) => {
                        return (
                            <div className={styles.mvSummaryArea} key={a.id}>
                                <div className={styles.mvSummaryAreaMain}>
                                    <h3>
                                        {a.result?.straatnaam}{" "}
                                        {a.result?.huisnr}
                                        {a.result?.huisnr_bag_letter
                                            ? `${a.result?.huisnr_bag_letter}${
                                                  a.result
                                                      ?.huisnr_bag_toevoeging ||
                                                  ""
                                              }`
                                            : ""}
                                        , {a.result?.postcode}{" "}
                                        {a.result?.plaatsnaam}
                                    </h3>
                                    <span>
                                        {a.selectedAddresses.length}{" "}
                                        <Text
                                            id="general.labels.addresses"
                                            plural={a.selectedAddresses.length}
                                        >{`adress${
                                            a.selectedAddresses.length === 1
                                                ? ""
                                                : "ses"
                                        }`}</Text>
                                    </span>
                                </div>
                                <span>
                                    {formatCurrencyValue(
                                        a.selectedAddresses.length *
                                            pricePerAddress
                                    )}
                                </span>
                                <button
                                    onClick={() => {
                                        if (!onAreaRemove) {
                                            return;
                                        }
                                        onAreaRemove(a);
                                    }}
                                    disabled={a.disabled}
                                    className={styles.mvSummaryAreaRemoveButton}
                                >
                                    <CloseIcon
                                        className={
                                            styles.mvSummaryRemoveAreaIcon
                                        }
                                    />
                                </button>
                            </div>
                        );
                    })}
                <div className={styles.mvSummaryTotal}>
                    <div className={styles.mvSummaryAreaMain}>
                        <span>
                            <Text id="general.labels.total">Total</Text>:{" "}
                            {addressCount}{" "}
                            <Text
                                id="general.buttons.addressesSelected"
                                plural={addressCount}
                            >
                                {`adress${addressCount === 1 ? "" : "ses"}
                             selected`}
                            </Text>
                        </span>
                    </div>
                    <span>{formatCurrencyValue(totalPrice)}</span>
                </div>
            </div>

            <div className={styles.mvSummaryFooter}>
                <Button type="secondary" onClick={onClose}>
                    <LongArrowIcon
                        fill="currentColor"
                        className={styles.mvSummaryBackIcon}
                    />
                    <Text id="general.buttons.back">Back</Text>
                </Button>
            </div>
        </div>
    );
};

export default Summary;
