import { h, FunctionalComponent } from "preact";
import { useMemo } from "preact/hooks";
import {
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
    useRef,
} from "preact/compat";
import {
    MapContainer,
    TileLayer,
    Marker,
    Circle,
    Popup,
    useMap,
    useMapEvents,
} from "react-leaflet";
import Button from "../Button";
import styles from "./style.scss";

import L, { LatLng, LeafletEventHandlerFnMap } from "leaflet";
import "leaflet/dist/leaflet.css";

// Some temp fixes to make leaflet markers work again
// @ts-ignore:
import markerIcon2x from "../../images/marker-blue-2x.png";
// @ts-ignore:
import markerIcon from "../../images/marker-blue.png";
// @ts-ignore:
import markerIconSelected2x from "../../images/marker-orange-2x.png";
// @ts-ignore:
import markerIconSelected from "../../images/marker-orange.png";
// @ts-ignore:
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import MvArea from "../../models/MvArea";
import MapMarker from "../../icons/MapMarker";
import CloseIcon from "../../icons/CloseIcon";
import { getLocations } from "../../services/api";
import LoadingIcon from "../../icons/LoadingIcon";
import MvAdressResult from "../../models/MvAddressResult";
import { Text } from "preact-i18n";

// @ts-ignore:
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    iconSize: [25, 34],
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    shadowAnchor: [13, 46],
});

export interface MapProps {
    initialAreas: MvArea[];
    apiBasePath?: string;
    onSelect(area: MvArea): void;
    addAddress(address: MvAdressResult): void;
    updateAddress(area: MvArea, newReult: MvAdressResult): void;
    countryCode?: "nl" | "de" | "be" | "fr" | "en";
    isLoading: boolean;
    startLoading?(): void;
    stopLoading?(): void;
}

export interface CenterMapProps {
    areas: MvArea[];
}

const CenterMap: FunctionalComponent<CenterMapProps> = ({
    areas,
}: CenterMapProps) => {
    const map = useMap();

    const centerMap = (mapInstance: any, areas: MvArea[]) => {
        let visibleCircles: any[] = [];
        const filteredAreas = areas.filter((area) => area.result !== null);
        if (filteredAreas.length > 0) {
            filteredAreas.forEach((a) => {
                const bounds = L.latLng(
                    a.result?.breedtegraad ?? 0,
                    a.result?.lengtegraad || 0
                ).toBounds(a.inputParams.radius ?? 300);
                visibleCircles.push(bounds);
            });

            // Fit the map with the visible markers bounds
            mapInstance.flyToBounds(visibleCircles, {
                padding: [84, 84],
                animate: true,
                duration: 0.5,
            });
        }
    };

    useEffect(() => {
        centerMap(map, areas);
    }, [areas]);

    return null;
};

interface CustomMarkerProps {
    area: MvArea;
    onSelect(area: MvArea): void;
    onPositionUpdate(newPos: LatLng, area: MvArea): void;
    [x: string]: any;
}

const CustomMarker: FunctionalComponent<CustomMarkerProps> = ({
    area,
    onSelect,
    onPositionUpdate,
    ...restProps
}: CustomMarkerProps) => {
    const map = useMap();
    const markerRef: any = useRef();
    const eventHandlers: LeafletEventHandlerFnMap = useMemo(
        () => ({
            mouseover() {
                if (
                    L.DomUtil.hasClass(
                        map.getContainer(),
                        "crosshair-cursor-enabled"
                    )
                ) {
                    L.DomUtil.removeClass(
                        map.getContainer(),
                        "crosshair-cursor-enabled"
                    );
                }
            },
            mouseout() {
                if (
                    !L.DomUtil.hasClass(
                        map.getContainer(),
                        "crosshair-cursor-enabled"
                    )
                ) {
                    L.DomUtil.addClass(
                        map.getContainer(),
                        "crosshair-cursor-enabled"
                    );
                }
            },
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    onPositionUpdate(marker.getLatLng(), area);
                }
            },
            click: () => {
                onSelect(area);
            },
        }),
        []
    );
    useEffect(() => {
        if (area.isSelected) {
            markerRef.current?.openPopup();
        } else {
            markerRef.current?.closePopup();
        }
    }, [area.isSelected]);
    return (
        <Marker ref={markerRef} eventHandlers={eventHandlers} {...restProps} />
    );
};

interface DynamicMarkersProps {
    disabled: boolean;
    onIsAdding(value: boolean): void;
    onAddressSelected(latLng: LatLng): void;
}

const DynamicMarkers: FunctionalComponent<DynamicMarkersProps> = ({
    disabled,
    onIsAdding,
    onAddressSelected,
}: DynamicMarkersProps) => {
    const [isAdding, setIsAdding] = useState(false);

    const map = useMap();

    useEffect(() => {
        onIsAdding(isAdding);
    }, [isAdding]);

    useMapEvents({
        click: ({ latlng, originalEvent }: any) => {
            const foundTargets = Object.keys(originalEvent.target.classList)
                .map((k) => originalEvent.target.classList[k])
                .filter((c: any) => c.includes("addMarker"));

            /**  Don't add marker if click is on add marker button or is disabled */
            if (!isAdding || foundTargets.length || disabled) {
                return;
            }
            onAddressSelected(latlng);
        },
    });

    const handleButtonClick = () => {
        setIsAdding(!isAdding);
        if (isAdding) {
            L.DomUtil.removeClass(
                map.getContainer(),
                "crosshair-cursor-enabled"
            );
            // L.DomUtil.addClass(map.getContainer(), "leaflet-grab");
        } else {
            // L.DomUtil.removeClass(map.getContainer(), "leaflet-grab");
            L.DomUtil.addClass(map.getContainer(), "crosshair-cursor-enabled");
        }
    };

    return (
        <Button
            type="tertiary"
            className={styles.addMarkerButton}
            disabled={disabled}
            onClick={handleButtonClick}
        >
            {isAdding ? (
                <CloseIcon
                    fill={"#ffffff"}
                    className={styles.addMarkerCloseIcon}
                />
            ) : (
                <MapMarker fill={"#ffffff"} className={styles.addMarkerIcon} />
            )}
            {!isAdding && <span>+</span>}
        </Button>
    );
};

const Map: FunctionalComponent<MapProps> = forwardRef(
    (
        {
            initialAreas = [],
            onSelect,
            apiBasePath,
            addAddress,
            updateAddress,
            countryCode,
            isLoading: isLoadingNow,
            startLoading,
            stopLoading,
        }: MapProps,
        ref: any
    ) => {
        const [areas, setAreas] = useState<MvArea[]>(initialAreas);
        const [isAdding, setIsAdding] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [notFound, setNotFound] = useState<boolean>(false);
        const timerRef: any = useRef(null);

        const mapMarkers = areas.filter((area) => area.result !== null);

        useEffect(() => {
            if (isLoading) {
                startLoading && startLoading();
            } else {
                stopLoading && stopLoading();
            }
        }, [isLoading]);

        const showNotfound = () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            setNotFound(true);
            timerRef.current = setTimeout(() => {
                setNotFound(false);
            }, 2000);
        };

        const searchLocation = async (latLng: LatLng) => {
            const params = {
                c: "objects",
                latitude: latLng.lat,
                longitude: latLng.lng,
                distance: 0.05,
                countryCode: countryCode || "nl",
            };
            const locations = await getLocations(
                apiBasePath || "localhost:3000",
                params
            );

            return locations;
        };

        const handleAddressSelected = async (
            latlng: LatLng,
            currentArea?: MvArea
        ) => {
            setIsLoading(true);
            const {
                data: { data },
            } = await searchLocation(latlng);

            if (!data.length) {
                setIsLoading(false);
                return showNotfound();
            }

            const result = data[0];

            setIsLoading(false);
            // Update or add new area
            return currentArea
                ? updateAddress(currentArea, result)
                : addAddress(result);
        };

        /**
         * Make this update function callable from the outside by using forwardRef and useImperativeHandle
         * https://preactjs.com/guide/v10/switching-to-preact/#forwardref
         */
        useImperativeHandle(ref, () => ({
            update: (areas: MvArea[]) => {
                setAreas(areas);
            },
        }));

        return (
            <div className={styles.mvMapWrapper}>
                <MapContainer
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                    center={[52.370216, 4.895168]}
                    zoom={7}
                    preferCanvas={true}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {mapMarkers.map((a: MvArea, index) => (
                        <CustomMarker
                            key={index}
                            area={a}
                            onPositionUpdate={handleAddressSelected}
                            position={[
                                a.result?.breedtegraad,
                                a.result?.lengtegraad,
                            ]}
                            icon={
                                a.isSelected
                                    ? new L.Icon.Default({
                                          iconUrl: markerIconSelected,
                                          iconRetinaUrl: markerIconSelected2x,
                                          className: !a.disabled
                                              ? "icon-drag"
                                              : "",
                                      })
                                    : new L.Icon.Default({
                                          className: !a.disabled
                                              ? "icon-drag"
                                              : "",
                                      })
                            }
                            onSelect={onSelect}
                            draggable={!a.disabled}
                        >
                            {!isAdding && (
                                <Popup>
                                    <strong>
                                        {a.result?.straatnaam}{" "}
                                        {a.result?.huisnr}
                                        {a.result?.huisnr_bag_letter
                                            ? `${a.result?.huisnr_bag_letter}${
                                                  a.result
                                                      ?.huisnr_bag_toevoeging ||
                                                  ""
                                              }`
                                            : ""}
                                    </strong>
                                    <br />
                                    {a.result?.postcode}, {a.result?.plaatsnaam}
                                </Popup>
                            )}
                            <Circle
                                radius={a.inputParams.radius}
                                center={[
                                    a.result?.breedtegraad,
                                    a.result?.lengtegraad,
                                ]}
                                disabled={true}
                                pathOptions={{
                                    color: a.isSelected ? "#F28E3D" : "#14456B",
                                }}
                            />
                        </CustomMarker>
                    ))}
                    <CenterMap areas={areas} />
                    <DynamicMarkers
                        disabled={isLoadingNow}
                        onIsAdding={(val: boolean) => setIsAdding(val)}
                        onAddressSelected={(latLng: LatLng) =>
                            handleAddressSelected(latLng)
                        }
                    />
                    <div
                        className={`${styles.loadingOverlay} ${
                            isLoading ? styles.loadingShow : null
                        }`}
                    >
                        <LoadingIcon
                            fill="#14456b"
                            className={styles.loadingOverlayIcon}
                        />
                    </div>
                    <div
                        className={`${styles.addMarkerMessage} ${
                            notFound ? styles.showMessage : null
                        }`}
                    >
                        <Text id="general.messages.addressNotFound" plural={1}>
                            No address found
                        </Text>
                    </div>
                </MapContainer>
            </div>
        );
    }
);

export default Map;
