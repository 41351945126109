import { h, FunctionalComponent, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";
import styles from "./style.scss";

export interface RangeSliderProps {
    label?: string;
    isSingle?: boolean;
    minValue?: number;
    maxValue?: number;
    hasInputs?: boolean;
    value?: any;
    onChange?(value: number | number[]): void;
}

const RangeSlider: FunctionalComponent<RangeSliderProps> = ({
    label = "Radius (m)",
    isSingle = false,
    minValue = 10,
    maxValue = 2000,
    hasInputs = true,
    value,
    onChange,
}: RangeSliderProps) => {
    const [singleValue, setSingleValue] = useState(value || 300);
    const [multiValue, setMultiValue] = useState(value || [300, 500]);

    useEffect(() => {
        if (!onChange) {
            return;
        }
        onChange(isSingle ? singleValue : multiValue);
    }, [singleValue, multiValue]);

    const updateSliderValue = (val: any) => {
        if (isSingle) {
            setSingleValue(val);
        } else {
            setMultiValue(val);
        }
    };

    const sliderProps = {
        min: minValue,
        max: maxValue,
        step: 10,
        keyboard: false,
        marks: {
            [minValue]: {
                style: { textAlign: "left", transform: "translateX(-35%)" },
                label: `${minValue}`,
            },
            [maxValue]: {
                style: { textAlign: "right", transform: "translateX(-85%)" },
                label: `${maxValue}`,
            },
        },
        trackStyle: { border: "solid 2px #14456B" },
        handleStyle: {
            backgroundColor: "#14456B",
            border: "solid 2px #14456B",
            width: "16px",
            height: "16px",
            opacity: 1,
        },
        activeDotStyle: {
            borderColor: "#14456B",
        },
        allowCross: false,
        value: isSingle ? singleValue : multiValue,
        range: !isSingle,
        onChange: updateSliderValue,
    };

    const handleInputChange = (e: any) => {
        let val = Number((e.target as HTMLInputElement).value);
        if (val < minValue) {
            val = minValue;
        }
        if (val > maxValue) {
            val = maxValue;
        }
        setSingleValue(val);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" || event.key === "Tab") {
            // 👇 Get input value
            handleInputChange(event);
        }
    };

    return (
        <div className={styles.mvRangeSliderWrapper}>
            <div className={styles.mvRangeSliderTop}>
                <span className={styles.mvRangeSliderLabel}>{label}</span>
                {hasInputs && (
                    <div className={styles.mvRangeSliderInputs}>
                        {isSingle && (
                            <input
                                type="number"
                                name="singleValue"
                                min={minValue}
                                max={maxValue}
                                onBlur={handleInputChange}
                                onKeyDown={handleKeyDown}
                                value={singleValue}
                            />
                        )}
                        {!isSingle && (
                            <Fragment>
                                <input
                                    type="number"
                                    name="minValue"
                                    min={minValue}
                                    max={maxValue}
                                    onChange={(e) => {
                                        setMultiValue(
                                            (currentVal: number[]) => [
                                                Number(
                                                    (
                                                        e.target as HTMLInputElement
                                                    ).value
                                                ),
                                                currentVal[1],
                                            ]
                                        );
                                    }}
                                    value={multiValue[0]}
                                />
                                <span> - </span>
                                <input
                                    type="number"
                                    name="maxValue"
                                    min={minValue}
                                    max={maxValue}
                                    value={multiValue[1]}
                                    onChange={(e) => {
                                        setMultiValue(
                                            (currentVal: number[]) => [
                                                currentVal[0],
                                                Number(
                                                    (
                                                        e.target as HTMLInputElement
                                                    ).value
                                                ),
                                            ]
                                        );
                                    }}
                                />
                            </Fragment>
                        )}
                    </div>
                )}
            </div>

            <Slider {...sliderProps} />
        </div>
    );
};

export default RangeSlider;
