import ReactModal from "react-modal";
import { h, FunctionalComponent } from "preact";

import styles from "./style.scss";

export interface ModalProps {
    children?: any;
    useModal?: boolean;
    isOpen: boolean;
    apiBasePath?: string;
    onRequestClose?(): any;
    containerId: string;
    shouldCloseOnOverlayClick?: boolean;
}

const Modal: FunctionalComponent<ModalProps> = (props: ModalProps) => {
    const {
        containerId,
        shouldCloseOnOverlayClick = false,
        children,
        isOpen,
        onRequestClose,
    } = props;

    const container = document.getElementById(containerId);

    if (container) {
        ReactModal.setAppElement(container);
    }

    return (
        <ReactModal
            isOpen={isOpen}
            className={styles.mvContainerModal}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    overflow: "auto",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    zIndex: "10000",
                },
                content: {
                    background: "#ffffff",
                    padding: "0px",
                    overflow: "hidden",
                    color: "#454545",
                    zIndex: "10010",
                },
            }}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            {children}
        </ReactModal>
    );
};

const StaticWrapper: FunctionalComponent<ModalProps> = (props: ModalProps) => {
    const { children } = props;

    return <div className={styles.kivContainer}>{children}</div>;
};

/**
 * Modal wrapper component
 * Wraps all components inside a modal (if useModal is true), otherwise uses a container element
 * @param  {ComponentProps} props: ModalProps
 * @return {Element} TXS element
 */
const ModalWrapper = (props: ModalProps) => {
    return props.useModal ? (
        <Modal {...props}>{props.children}</Modal>
    ) : (
        <StaticWrapper {...props}>{props.children}</StaticWrapper>
    );
};

export default ModalWrapper;
